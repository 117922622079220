<template>
  <div>
    <!-- pc高59 手机高44，不包括border -->
    <div class="top-container" :class="{'top-container-pc':isPc}">
      <div class="top-content">
        <div class="logo-container pointer" @click="toHome">
          <van-image :src="require('@/assets/logo.jpg')" height="30" />
        </div>
        <div class="search-container">
          <Search v-if="isPc" />
        </div>
        <div class="button-container" id="top-button-container">
          <!--pc-->
          <div v-if="isPc" style="display:flex;">
            <van-popover v-model="showCountryPopup" trigger="click" placement="bottom-start" get-container="#top-button-container">
              <div class="select-container" style="width: 180px;">
                <van-cell clickable :title="$t('All')" @click="changeCountry(0)" :class="{active:countryId===0}">
                  <van-icon name="success" class="active" v-show="countryId===0" size="24" />
                </van-cell>
                <van-cell v-for="item in countryList" :key="item.id" :title="$i18n.locale === 'th'?item.nameTh:item.name" clickable
                  @click="changeCountry(item.id)" :class="{active:countryId===item.id}" :icon="item.icon">
                  <van-icon name="success" class="active" v-show="countryId===item.id" size="24" />
                </van-cell>
              </div>
              <template #reference>
                <van-icon name="bars" size="28" class="pointer-icon" :class="{visibility:$route.path!=='/'}" />
              </template>
            </van-popover>
            <div class="lang pointer" @click="changeLang()">
              <span v-if="$i18n.locale==='th'">En</span>
              <span v-else>Th</span>
            </div>
          </div>
          <!--mobile-->
          <div v-else>
            <van-icon name="search" size="28" style="margin-right:10px;" @click="toQuery" />
            <van-icon name="bars" size="28" @click="showCountryPopup=true" v-if="$route.path==='/'" />
          </div>
        </div>
      </div>
    </div>
    <div class="placeholder" :class="{'placeholder-pc':isPc}"></div>
    <!--mobile 右边弹出-->
    <van-popup v-model="showCountryPopup" position="right" :style="{ width: '60%',height:'100%' }" closeable v-if="!isPc">
      <div class="select-container" style="padding-top: 48px;">
        <van-cell-group>
          <van-cell clickable :title="$t('All')" @click="changeCountry(0)" :class="{active:countryId===0}">
            <van-icon name="success" class="active" v-show="countryId===0" />
          </van-cell>
          <van-cell v-for="item in countryList" :key="item.id" :title="$i18n.locale === 'th'?item.nameTh:item.name" clickable @click="changeCountry(item.id)"
            :class="{active:countryId===item.id}" :icon="item.icon">
            <van-icon name="success" class="active" v-show="countryId===item.id" />
          </van-cell>
        </van-cell-group>
        <van-cell-group style="margin-top:30px">
          <van-cell v-if="$i18n.locale==='th'" clickable title="English" @click="changeLang()">
          </van-cell>
          <van-cell v-else clickable title="ไทย" @click="changeLang()"></van-cell>
        </van-cell-group>

      </div>
    </van-popup>
  </div>
</template>

<script>
import Search from "./Search.vue";
export default {
  components: { Search },
  data() {
    return {
      countryList: [],
      countryId: 0,
      showCountryPopup: false,
    };
  },
  props: ["id"],
  created() {
    this.countryId = parseInt(this.id);
    this.getCountryList();
  },
  methods: {
    getCountryList() {
      this.request("/country", {}).then((response) => {
        this.countryList = response;
      });
    },
    changeCountry(id) {
      if (this.countryId === id) {
        return false;
      }
      this.countryId = id;
      this.$emit("func", this.countryId);
    },
    toQuery() {
      this.$router.push("/query");
    },
    toHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    changeLang() {
      if (this.$i18n.locale === "th") {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      } else {
        this.$i18n.locale = "th";
        localStorage.setItem("lang", "th");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.visibility {
  visibility: hidden;
}

.top-container {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 45px;
  border-bottom: 1px solid @my-border-color;
  z-index: 1000;
  background-color: white;
  .top-content {
    width: 100%;
    height: 100%;
    display: flex;
  }
}
.placeholder {
  height: 45px;
}
.placeholder-pc {
  height: 60px;
}
//PC补充
.top-container-pc {
  height: 60px;
  .top-content {
    width: 1200px;
    margin: 0px auto;
  }
}

.logo-container,
.button-container {
  display: flex;
  flex: none;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  .lang {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    color: @my-icon-color;
    &:hover {
      color: @my-blue;
    }
  }
}

.search-container {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
}

.select-container {
  font-weight: bold;
  font-size: 14px;
  /deep/ .van-icon {
    font-size: 20px;
  }
  .active {
    color: @my-blue;
  }
  .van-cell__left-icon {
    margin-right: 10px;
  }
  .van-cell__value {
    height: 24px;
  }
}
</style>