<template>
  <div id="app">
    <keep-alive exclude="Detail">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
html,
body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
  font-size: calc(100vw / 48);
}

body {
  font-size: 12px;
}

#nprogress .bar {
  background: @my-blue !important;
}

#nprogress .spinner,
#nprogress .spinner-icon {
  display: none;
}

.iconfont,
.van-icon {
  color: @my-icon-color;
}

.iconfont-active {
  color: @my-icon-color-active;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}
.pointer-icon {
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    color: @my-blue;
    background-color: @my-bg-blue;
  }
}
</style>