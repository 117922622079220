<template>
  <div>
    <div class="container">
      <van-list v-model="loading" :finished="finished" @load="onLoad" offset="1" :loading-text="$t('Loading')"  :finished-text="$t('NoMoreData')">
        <van-grid :column-num="3" gutter="2" :square="true">
          <van-grid-item v-for="(item,index) in list" :key="index">
            <div class="image-container pointer" @click="$router.push('/detail/'+item.id)">
              <van-image :src="item.thumbnailUrl" fit="cover" />
              <div class="tag" v-if="item.isVideo">{{videoTime(item.videoLength)}}</div>
              <div class="tag" v-else>+ {{item.photoCount}}</div>
            </div>
          </van-grid-item>
        </van-grid>
      </van-list>
    </div>
  </div>
</template>

<script>
import { request } from "@/http/request";
import { videoTime } from "@/utils";
export default {
  name: "Tab1",
  props: ["id"],
  data() {
    return {
      page: 1,
      pageSize: 15,
      list: [],
      loading: false,
      finished: false,
    };
  },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.NProgress.start();
      this.loading = true;
      request("/media", {
        page: this.page,
        pageSize: this.pageSize,
        hostId: this.id,
      }).then((response) => {
        this.list = this.page === 1 ? response : this.list.concat(response);
        this.loading = false;
        if (response.length < this.pageSize) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.NProgress.done();
      });
    },
    onLoad() {
      this.page++;
      this.getList();
    },
    videoTime(time) {
      return videoTime(time);
    },
  },

  watch: {
    id() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-grid-item__content {
  padding: 0px;
}
.image-container {
  width: 100%;
  padding-bottom: 100%;
  height: 0px;
  position: relative;
  .van-image {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
.tag {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  padding: 3px 4px;
  border-radius: 2px;
  font-weight: 300;
  margin: 4px;
}
</style>