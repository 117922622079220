<template>
  <div>
    <div class="container">
      <van-list v-model="loading" :finished="finished" @load="onLoad" offset="1" :loading-text="$t('Loading')" :finished-text="$t('NoMoreData')">
        <div v-for="(item,index) in list" :key="index">
          <div v-if="item.isVideo" class="video-container">
            <video :poster="item.thumbnailUrl" controls controlslist="nodownload" @contextmenu="handleMouse">
              <source :src="item.videoUrl" type="video/mp4">
            </video>
          </div>
          <div v-else class="photo-container">
            <van-swipe :ref="'swipe'+item.id">
              <van-swipe-item v-for="(image) in item.photoUrls.split(',')" :key="image">
                <img v-lazy="image" />
              </van-swipe-item>
            </van-swipe>
            <div>
              <div class="arrow-left pointer" v-if="item.photoCount>1" @click="preImg(item.id)">
                <van-icon name="arrow-left" size="18" />
              </div>
              <div class="arrow-right pointer" v-if="item.photoCount>1" @click="nextImg(item.id)">
                <van-icon name="arrow" size="18" />
              </div>
            </div>
          </div>
          <div class="bottom-container">
            <div class="icon-container">
              <div @click="login()">
                <van-icon name="chat-o" class="pointer" />
              </div>
              <div @click="login()">
                <van-icon name="like-o" class="pointer" />
              </div>
              <div @click="login()">
                <van-icon name="bookmark-o" class="pointer" />
              </div>
            </div>
            <div class="text-container">
              <div v-html="transferBr(item.title)"></div>
            </div>
            <div class="time-container">
              <div>{{$t('Updated')}} {{timeAgo(item.time)}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { request } from "@/http/request";
import { videoTime, transferBr, timeAgo } from "@/utils";
export default {
  name: "Tab2",
  props: ["id"],
  data() {
    return {
      page: 1,
      pageSize: 15,
      list: [],
      loading: false,
      finished: false,
    };
  },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.NProgress.start();
      this.loading = true;
      request("/media", {
        page: this.page,
        pageSize: this.pageSize,
        hostId: this.id,
      }).then((response) => {
        this.list = this.page === 1 ? response : this.list.concat(response);
        this.loading = false;
        if (response.length < this.pageSize) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.NProgress.done();
      });
    },
    onLoad() {
      this.page++;
      this.getList();
    },
    videoTime(time) {
      return videoTime(time);
    },
    transferBr(str) {
      return transferBr(str);
    },
    timeAgo(time) {
      return timeAgo(this.$i18n.locale, time);
    },
    preImg(id) {
      let ref = "swipe" + id;
      this.$refs[ref][0].prev();
    },
    nextImg(id) {
      let ref = "swipe" + id;
      this.$refs[ref][0].next();
    },
    login() {
      this.Notify({
        type: "primary",
        message: this.$t("LogFirst"),
        duration: 1000,
      });
    },
    handleMouse(e) {
      e.preventDefault();
    },
  },

  watch: {
    id() {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.video-container {
  video {
    width: 100%;
    height: auto;
  }
}

.photo-container {
  position: relative;
  .van-swipe {
    width: 100%;
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  .arrow-left,
  .arrow-right {
    width: 24px;
    height: 24px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: @my-bg-blue;
    }
  }
  .arrow-right {
    right: 10px;
    left: auto;
  }
}

.bottom-container {
  padding: 10px 10px;
  .icon-container {
    display: flex;
    .van-icon {
      color: #262626;
      font-size: 24px;
      font-weight: bold;
      margin-right: 10px;

      &:hover {
        color: @my-blue;
      }
    }
  }
  .text-container {
    line-height: 20px;
    font-size: 12px;
    margin-top: 10px;
    color: #262626;
  }
  .time-container {
    line-height: 22px;
    font-size: 12px;
    margin-top: 5px;
    color: #8e8e8e;
  }
}
</style>
