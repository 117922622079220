import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import "@vant/touch-emulator";
import "@/assets/iconfont/iconfont.css";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {
  request
} from "@/http/request";
import {
  isMobile
} from "@/utils";

Vue.prototype.NProgress = NProgress;
Vue.prototype.request = request;
Vue.prototype.isPc = !isMobile();

import {
  Button,
  Icon,
  Image,
  Popup,
  Search,
  Loading,
  NavBar,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  PullRefresh,
  List,
  Tab,
  Tabs,
  Tag,
  ImagePreview,
  Popover,
  Tabbar,
  TabbarItem,
  Empty,
  Notify,
  Sticky,
  Swipe,
  SwipeItem,
  Lazyload,
} from "vant";

// import VueTouch from 'vue-touch'

Vue.use(Button);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Loading);
Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(ImagePreview);
Vue.use(Popover);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Empty);
Vue.use(Notify);
Vue.use(Sticky);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);

Vue.prototype.Notify = Notify;

// Vue.use(VueTouch, {
//   name: 'v-touch'
// });
Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: (function () {
    if (localStorage.getItem("lang")) {
      return localStorage.getItem("lang");
    }
    if (
      (navigator.language || navigator.browserLanguage)
      .toLowerCase()
      .includes("th")
    ) {
      return "th";
    }
    return "en";
  })(),
  messages: {
    en: require("@/i18n/locale/en"),
    th: require("@/i18n/locale/th"),
  },
});


new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");