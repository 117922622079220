<template>

  <div class="menu-container" ref="container">
    <div>
      <div class="item item-active" @click="toHome">
        <div class="icon">
          <van-icon name="wap-home-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('Home')}}</div>
        </div>
      </div>

      <div class="item" @click="login()">
        <div class="icon">
          <van-icon name="chat-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('Messages')}}</div>
        </div>
      </div>

      <div class="item" @click="login()">
        <div class="icon">
          <van-icon name="like-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('Subscriptions')}}</div>
        </div>
      </div>

      <div class="item" @click="login()">
        <div class="icon">
          <van-icon name="bookmark-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('Bookmarks')}}</div>
        </div>
      </div>

      <div class="item" @click="login()">
        <div class="icon">
          <van-icon name="user-circle-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('Profles')}}</div>
        </div>
      </div>

      <div class="item" @click="login()">
        <div class="icon">
          <van-icon name="more-o" size="33" />
        </div>
        <div class="name">
          <div>{{$t('More')}}...</div>
        </div>
      </div>

      <div class="button" @click="toLogin()">{{$t('Login')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    login() {
      this.Notify({
        type: "primary",
        message: this.$t('LogFirst'),
        duration: 1000,
      });
    },
    toLogin() {
      this.Notify({
        type: "warning",
        message: this.$t('NotSupportRegion'),
        duration: 1000,
      });
    },
    toHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-container {
  margin-top: 15px;
  width: 270px;
  position: fixed;
  .item {
    color: @my-icon-color;
    display: flex;
    margin-top: 10px;
    height: 48px;
    width: 270px;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 0px 20px;
    cursor: pointer;
    &:hover {
      background-color: @my-bg-blue;
      color: @my-blue;
      .icon {
        .van-icon {
          color: @my-blue;
        }
      }
    }

    .icon {
      height: 33px;
      .van-icon {
        color: @my-icon-color;
      }
    }
    .name {
      height: 33px;
      font-size: 19px;
      line-height: 33px;
      font-weight: bold;
      margin-left: 21px;
    }
  }
  .item-active {
    color: @my-icon-color-active;
    .icon {
      .van-icon {
        font-weight: bold;
        color: @my-icon-color-active;
      }
    }
  }

  .button {
    margin-top: 20px;
    height: 48px;
    width: 100%;
    background: @my-button-blue;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    line-height: 48px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
      background: @my-button-bg-blue;
    }
  }
}
</style>