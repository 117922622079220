module.exports = {
    Home: "Home",
    Messages: "Messages",
    Subscriptions: "Subscriptions",
    Bookmarks: "Bookmarks",
    Profles: "My Profles",
    More: "More",
    Login: "Login",
    Search: "Search",
    RECOMMEND: "RECOMMEND",
    Posts: "Posts",
    LastUpdate: "Last Update",
    Updated:"Updated",
    NoMoreData: "No More Data.",
    Loading: "Loading...",
    All: "All",
    Recent: "Recent",
    Norecentsearch: "No recent search",
    ClearAll:"Clear All",
    Noresultsearch:"No result searches",
    LogFirst:"Sorry! Please log in first!",
    NotSupportRegion:"Login is not supported in your region!",

};