module.exports = {
    Home: "หน้าหลัก",
    Messages: "ข้อความ",
    Subscriptions: "การสมัครรับข้อมูล",
    Bookmarks: "บุคมาร์ค",
    Profles: "ประวัติของฉัน",
    More: "มากกว่า",
    Login: "เข้าสู่ระบบ",
    Search: "ค้นหา",
    RECOMMEND: "แนะนำ",
    Posts: "กระทู้",
    LastUpdate: "อัพเดทล่าสุด",
    Updated:"อัปเดต",
    NoMoreData: "ไม่มีข้อมูลเพิ่มเติม.",
    Loading: "กำลังโหลด...",
    All: "ทั้งหมด",
    Recent: "ล่าสุด",
    Norecentsearch: "ไม่มีการค้นหาล่าสุด",
    ClearAll:"ลบทั้งหมด",
    Noresultsearch:"ไม่มีผลการค้นหา",
    LogFirst:"ขออภัย! โปรดเข้าสู่ระบบก่อน!",
    NotSupportRegion:"ไม่รองรับการเข้าสู่ระบบในภูมิภาคของคุณ!",
};