<template>
  <div>
    <div class="container">
      <Top />
      <div class="content" :class="{ 'pc-content': isPc }">
        <!-- 左侧菜单 -->
        <div class="menu" v-if="isPc">
          <Menu />
        </div>
        <!-- 中间 -->
        <div class="list" :class="{ 'pc-list': isPc }">
          <user-top :id="id" />
          <user-bottom :id="id" />
        </div>

        <!-- 右侧推荐 -->
        <div class="recommend" v-if="isPc">
          <Recommend />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Menu from "@/components/Menu.vue";
import Recommend from "@/components/Recommend.vue";
import UserTop from "./user/UserTop.vue";
import UserBottom from "./user/UserBottom.vue";
export default {
  name: "User",
  props: ["id"],
  components: { Top, Menu, Recommend, UserTop, UserBottom },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}

.pc-content {
  width: 1200px;
  margin: 0px auto;
  display: flex;
  .menu {
    width: 299px;
    box-sizing: border-box;
  }
  .recommend {
    width: 299px;
    box-sizing: border-box;
  }
}
.list {
  width: 100%;
}
.pc-list {
  width: 600px;
  border-left: 1px solid @my-border-color;
  border-right: 1px solid @my-border-color;
  box-sizing: content-box;
}
</style>
