<template>
  <div>
    <div class="container">
      <Top />
      <div class="content" :class="{'pc-content':isPc}">
        <!-- 左侧菜单 -->
        <div class="menu" v-if="isPc">
          <Menu />
        </div>
        <!-- 中间 -->
        <div class="list" :class="{'pc-list':isPc}">
          <div class="host">
            <div class="avatar pointer" @click="$router.push('/user/'+host.id)">
              <van-image :src="host.avatar" fit="cover" round />
            </div>
            <div class="name pointer" @click="$router.push('/user/'+host.id)">{{host.name}}</div>
          </div>

          <div v-if="item.isVideo" class="video-container" @contextmenu="handleMouse">
            <video :poster="item.thumbnailUrl" controls="true" controlslist="nodownload" autoplay>
              <source :src="item.videoUrl" type="video/mp4">
            </video>
          </div>
          <div v-else class="photo-container">
            <van-swipe :ref="'swipe'+item.id">
              <van-swipe-item v-for="(image) in photoUrls" :key="image">
                <img v-lazy="image" />
              </van-swipe-item>
            </van-swipe>
            <div>
              <div class="arrow-left pointer" v-if="item.photoCount>1" @click="preImg(item.id)">
                <van-icon name="arrow-left" size="18" />
              </div>
              <div class="arrow-right pointer" v-if="item.photoCount>1" @click="nextImg(item.id)">
                <van-icon name="arrow" size="18" />
              </div>
            </div>
          </div>
          <div class="bottom-container">
            <div class="icon-container">
              <div @click="login()">
                <van-icon name="chat-o" class="pointer" />
              </div>
              <div @click="login()">
                <van-icon name="like-o" class="pointer" />
              </div>
              <div @click="login()">
                <van-icon name="bookmark-o" class="pointer" />
              </div>
            </div>
            <div class="text-container">
              <div v-html="transferBr(item.title)"></div>
            </div>
            <div class="time-container">
              <div>{{$t('Updated')}} {{timeAgo(item.time)}}</div>
            </div>
          </div>
        </div>
        <!-- 右侧推荐 -->
        <div class="recommend" v-if="isPc">
          <Recommend />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Menu from "@/components/Menu.vue";
import Recommend from "@/components/Recommend.vue";
import { request } from "@/http/request";
import { videoTime, transferBr, timeAgo } from "@/utils";
export default {
  name: "Detail",
  components: { Top, Menu, Recommend },
  props: ["id"],
  data() {
    return {
      item: {},
      host: {},
    };
  },
  created() {
    this.getItem();
  },
  methods: {
    getItem() {
      request("/media/get", { id: this.id }).then((response) => {
        this.item = response;
        request("/host/get", { hostId: this.item.hostId }).then((response) => {
          this.host = response;
        });
      });
    },
    videoTime(time) {
      return videoTime(time);
    },
    transferBr(str) {
      return transferBr(str);
    },
    timeAgo(time) {
      return timeAgo(this.$i18n.locale, time);
    },
    preImg(id) {
      let ref = "swipe" + id;
      this.$refs[ref].prev();
    },
    nextImg(id) {
      let ref = "swipe" + id;
      this.$refs[ref].next();
    },
    login() {
      this.Notify({
        type: "primary",
        message: this.$t("LogFirst"),
        duration: 1000,
      });
    },
    handleMouse(e) {
      e.preventDefault();
    },
  },
  computed: {
    photoUrls() {
      if (this.item.id) {
        return this.item.photoUrls.split(",");
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}

.pc-content {
  width: 1200px;
  margin: 0px auto;
  display: flex;
  .menu {
    width: 299px;
    box-sizing: border-box;
  }
  .recommend {
    width: 299px;
    box-sizing: border-box;
  }
}

.list {
  width: 100%;
  .host {
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    .avatar {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      .van-image {
        height: 100%;
        width: 100%;
      }
    }
    .name {
      font-size: 14px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
.pc-list {
  width: 600px;
  border-left: 1px solid @my-border-color;
  border-right: 1px solid @my-border-color;
  box-sizing: content-box;
}

.video-container {
  video {
    width: 100%;
    height: auto;
  }
}

.photo-container {
  position: relative;
  .van-swipe {
    width: 100%;
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  .arrow-left,
  .arrow-right {
    width: 24px;
    height: 24px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: @my-bg-blue;
    }
  }
  .arrow-right {
    right: 10px;
    left: auto;
  }
}

.bottom-container {
  padding: 10px 10px;
  .icon-container {
    display: flex;
    .van-icon {
      color: #262626;
      font-size: 24px;
      font-weight: bold;
      margin-right: 10px;

      &:hover {
        color: @my-blue;
      }
    }
  }
  .text-container {
    line-height: 20px;
    font-size: 12px;
    margin-top: 10px;
    color: #262626;
  }
  .time-container {
    line-height: 22px;
    font-size: 12px;
    margin-top: 5px;
    color: #8e8e8e;
  }
}
</style>
