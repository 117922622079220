<template>
  <div id="top-search">
    <van-popover v-model="showPopover" get-container="#top-search" :offset="[0,1]">
      <div class="popover-container">
        <!-- 历史搜索 -->
        <div class="history-container" v-show="!keyword">
          <div class="recent-container">
            <div class="recent-text">{{$t('Recent')}}</div>
            <div class="recent-button">
              <a href="javascript:void" @click="clear()" v-show="localList.length>0">{{$t('ClearAll')}}</a>
            </div>
          </div>
          <div class="empty" v-show="localList.length===0">
            <van-empty :description="$t('Norecentsearch')" :image="require('@/assets/svg/empty.svg')" />
          </div>
          <div class="list" v-show="!loading && localList.length>0">
            <van-cell v-for="item in localList" :key="item.id" @click="toUser(item)">
              <template #title>
                <div class="item-container">
                  <div class="item-left">
                    <van-image :src="item.avatar" fit="cover" round />
                  </div>
                  <div class="item-center">
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-country">{{$i18n.locale === 'th'?item.countryNameTh:item.countryName}}</div>
                  </div>
                  <div class="item-right">
                    <van-icon name="cross" size="20" @click.stop="clear(item.id)" />
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
        </div>

        <!-- 关键字搜索 -->
        <div class="current-container" v-show="keyword">
          <div class="loading" v-show="loading">
            <van-loading size="24" vertical>{{$t('Loading')}}</van-loading>
          </div>
          <div class="empty" v-show="!loading && list.length===0">
            <van-empty :description="$t('Noresultsearch')" :image="require('@/assets/svg/notfind.svg')" />
          </div>
          <div class="list" v-show="!loading && list.length>0">
            <van-cell v-for="item in list" :key="item.id" @click="toUser(item)">
              <template #title>
                <div class="item-container">
                  <div class="item-left">
                    <van-image :src="item.avatar" fit="cover" round />
                  </div>
                  <div class="item-center">
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-country">{{$i18n.locale === 'th'?item.countryNameTh:item.countryName}}</div>
                  </div>
                  <div class="item-right"></div>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <template #reference>
        <div class="search-container">
          <input @focus.stop="onFocus" :placeholder="$t('Search')" class="search-input" v-model="keyword" />
          <div class="icon-container">
            <van-icon name="search" size="22" v-show="!keyword" />
            <van-icon class="pointer-icon" name="cross" size="20" v-show="keyword" @click="keyword=''"/>
          </div>
        </div>
      </template>
    </van-popover>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Search",
  data() {
    return {
      page: 1,
      pageSize: 20,
      showPopover: false,
      loading: false,
      keyword: undefined,
      list: [],
      localList: [],
    };
  },
  created() {
    let localStorageHost = localStorage.getItem("searchList");
    if (localStorageHost) {
      this.localList = JSON.parse(localStorageHost);
    }
  },
  methods: {
    onFocus() {
      this.showPopover = true;
    },

    search: _.debounce(function () {
      this.loading = true;
      this.request("/search", {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
      }).then((response) => {
        this.list = response;
        this.loading = false;
      });
    }, 500),

    toUser(item) {
      let pos = -1;
      for (let i = 0; i < this.localList.length; i++) {
        if (item.id === this.localList[i].id) {
          pos = i;
          break;
        }
      }
      if (pos > -1) {
        this.localList.splice(pos, 1);
      }
      this.localList.unshift(item);
      localStorage.setItem("searchList", JSON.stringify(this.localList));
      this.$router.push({ path: `/user/${item.id}` });
    },
    clear(id) {
      if (id) {
        let pos = -1;
        for (let i = 0; i < this.localList.length; i++) {
          if (id === this.localList[i].id) {
            pos = i;
            break;
          }
        }
        if (pos > -1) {
          this.localList.splice(pos, 1);
        }
        localStorage.setItem("searchList", JSON.stringify(this.localList));
      } else {
        this.localList = [];
        localStorage.removeItem("searchList");
      }
    },
  },
  watch: {
    keyword(val) {
      if (val) {
        this.search();
      }
    },
  },
};
</script>

<style lang="less" scoped>
a {
  color: @my-blue;
  font-size: 14px;
}
//不显示箭头
#top-search {
  /deep/ .van-popover__arrow {
    border: 0px;
    display: none;
  }
}

.search-container {
  width: 420px;
  display: flex;
  align-items: center;
  position: relative;
  .search-input {
    height: 18px;
    width: 420px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: 1px solid @my-border-color;
    transition: border 0.05s;
    border-radius: 8px;
  }
  .icon-container {
    position: absolute;
    right: 10px;
  }
}

.van-cell:last-child::after,
.van-cell--borderless::after {
  display: block;
}

.popover-container {
  width: 420px;
  height: 362px;
  box-sizing: border-box;
  .current-container,
  .history-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.current-container,
.history-container {
  .list {
    height: 100%;
    width: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    overflow-y: auto;
    .van-cell {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .van-cell:hover {
      background-color: @my-bg-color;
    }
    .item-container {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      .item-left {
        flex: 0 0 54px;
        height: 54px;
        border: 1px solid @my-border-color;
        background-color: white;
        padding: 2px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        .van-image {
          height: 100%;
          width: 100%;
        }
      }
      .item-center {
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .item-name {
          margin-top: 10px;
          font-size: 14px;
          font-weight: bold;
          height: 20px;
        }
        .item-country {
          font-size: 14px;
          height: 20px;
        }
      }
      .item-right {
        flex: 0 0 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.history-container {
  .recent-container {
    margin-top: 15px;
    padding: 0px 15px;
    justify-self: flex-start;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .recent-text {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>