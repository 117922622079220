<template>
  <div>
    <div class="query-container">
      <div class="top">
        <div class="top-left center" >
          <van-icon name="arrow-left" size="22" @click="$router.back()"/>
        </div>
        <div class="top-center center">
          <form action="#" class="center">
            <input v-model="keyword" class="search-input" type="search" ref="inputVal" id="inputId" />
          </form>
          <div class="icon-container">
            <van-icon name="search" size="20" v-show="!keyword" />
            <van-icon name="cross" size="18" v-show="keyword" @click="keyword=''" />
          </div>
        </div>
      </div>
      <div class="placeholder"></div>

      <div class="bottom">
        <!-- 历史搜索 -->
        <div class="history-container" v-show="!keyword">
          <div class="recent-container">
            <div class="recent-text">{{$t('Recent')}}</div>
            <div class="recent-button">
              <a href="javascript:void" @click="clear()" v-show="localList.length>0">{{$t('ClearAll')}}</a>
            </div>
          </div>
          <div class="empty" v-show="localList.length===0">
            <van-empty :description="$t('Norecentsearch')" :image="require('@/assets/svg/empty.svg')" />
          </div>
          <div class="list" v-show="!loading && localList.length>0">
            <van-cell v-for="item in localList" :key="item.id" @click="toUser(item)">
              <template #title>
                <div class="item-container">
                  <div class="item-left">
                    <van-image :src="item.avatar" fit="cover" round />
                  </div>
                  <div class="item-center">
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-country">{{$i18n.locale === 'th'?item.countryNameTh:item.countryName}}</div>
                  </div>
                  <div class="item-right">
                    <van-icon name="cross" size="20" @click.stop="clear(item.id)" />
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
        </div>

        <!-- 关键字搜索 -->
        <div class="current-container" v-show="keyword">
          <div class="loading" v-show="loading">
            <van-loading size="24" vertical>{{$t('Loading')}}</van-loading>
          </div>
          <div class="empty" v-show="!loading && list.length===0">
            <van-empty :description="$t('Noresultsearch')" :image="require('@/assets/svg/notfind.svg')" />
          </div>
          <div class="list" v-show="!loading && list.length>0">
            <van-cell v-for="item in list" :key="item.id" @click="toUser(item)">
              <template #title>
                <div class="item-container">
                  <div class="item-left">
                    <van-image :src="item.avatar" fit="cover" round />
                  </div>
                  <div class="item-center">
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-country">{{$i18n.locale === 'th'?item.countryNameTh:item.countryName}}</div>
                  </div>
                  <div class="item-right"></div>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Query",
  data() {
    return {
      page: 1,
      pageSize: 20,
      showPopover: false,
      loading: false,
      keyword: undefined,
      list: [],
      localList: [],
    };
  },
  created() {
    let localStorageHost = localStorage.getItem("searchList");
    if (localStorageHost) {
      this.localList = JSON.parse(localStorageHost);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputVal.focus();
    });
    // this.$refs.inputVal.focus();
    // document.getElementById('inputId').focus()
  },
  methods: {
    onFocus() {
      this.showPopover = true;
    },

    search: _.debounce(function () {
      this.loading = true;
      this.request("/search", {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
      }).then((response) => {
        this.list = response;
        this.loading = false;
      });
    }, 500),

    toUser(item) {
      let pos = -1;
      for (let i = 0; i < this.localList.length; i++) {
        if (item.id === this.localList[i].id) {
          pos = i;
          break;
        }
      }
      if (pos > -1) {
        this.localList.splice(pos, 1);
      }
      this.localList.unshift(item);
      localStorage.setItem("searchList", JSON.stringify(this.localList));
      this.$router.push({ path: `/user/${item.id}` });
    },
    clear(id) {
      if (id) {
        let pos = -1;
        for (let i = 0; i < this.localList.length; i++) {
          if (id === this.localList[i].id) {
            pos = i;
            break;
          }
        }
        if (pos > -1) {
          this.localList.splice(pos, 1);
        }
        localStorage.setItem("searchList", JSON.stringify(this.localList));
      } else {
        this.localList = [];
        localStorage.removeItem("searchList");
      }
    },
  },
  watch: {
    keyword(val) {
      if (val) {
        this.search();
      }
    },
  },
};
</script>

<style lang="less" scoped>
input::-webkit-search-cancel-button {
  display: none;
}
input[type="search"]::-ms-clear {
  display: none;
}
a {
  color: @my-blue;
  font-size: 14px;
}
form {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding-right: 15px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  height: 44px;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
}
.placeholder {
  height: 44px;
}

.top-left {
  width: 44px;
  flex: none;
}
.top-center {
  flex: auto;
  height: 44px;
  width: 100%;
  position: relative;
  .search-input {
    height: 32px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 15px;
    background-color: #f7f8fa;
    border-radius: 8px;
    border: 0px;
    box-sizing: border-box;
  }
  .icon-container {
    position: absolute;
    right: 25px;
  }
}

.bottom {
  width: 100%;
  box-sizing: border-box;
  .current-container,
  .history-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.current-container,
.history-container {
  .list {
    height: 100%;
    width: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    overflow-y: auto;
    .van-cell {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .van-cell:hover {
      background-color: @my-bg-color;
    }
    .item-container {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      .item-left {
        flex: 0 0 54px;
        height: 54px;
        border: 1px solid @my-border-color;
        background-color: white;
        padding: 2px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        .van-image {
          height: 100%;
          width: 100%;
        }
      }
      .item-center {
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .item-name {
          margin-top: 10px;
          font-size: 14px;
          font-weight: bold;
          height: 20px;
        }
        .item-country {
          font-size: 14px;
          height: 20px;
        }
      }
      .item-right {
        flex: 0 0 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.history-container {
  .recent-container {
    margin-top: 15px;
    padding: 0px 15px;
    justify-self: flex-start;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .recent-text {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>