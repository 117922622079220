import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/Index'
import Query from '../pages/Query';
import User from '../pages/User';
import Detail from '../pages/Detail';

Vue.use(VueRouter)

const routes = [{
    name: "index",
    path: '/',
    component: Index
  },
  {
    name: "search",
    path: '/query',
    component: Query
  },
  {
    name: "user",
    path: "/user/:id",
    props: true,
    component: User
  },
  {
    name: "detail",
    path: "/detail/:id",
    props: true,
    component: Detail
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  // }
]




const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to) => {
  // 谷歌统计,路由监听
  window.gtag('config', 'G-6FRC7H9929', {
    page_title: to.name, //页面标题
    page_path: to.path, //页面路径
    //   page_location: location
  })
})
export default router