<template>
  <div>
    <van-tabbar v-model="name" border z-index="100" :before-change="beforeChange">
      <!-- 首页 -->
      <van-tabbar-item name="home">
        <template>
          <van-icon name="wap-home" class="iconfont-active" size="24" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item name="message">
        <template>
          <van-icon name="chat-o" size="24" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item name="like">
        <template>
          <van-icon name="like-o" size="24" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item name="bookmark">
        <template>
          <van-icon name="bookmark-o" size="24" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item name="user">
        <template>
          <van-icon name="user-circle-o" size="24" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Tabbar",
  props: ["tabbarName"],
  data() {
    return { name: "" };
  },
  mounted() {
    this.name = this.tabbarName;
  },
  methods: {
    beforeChange() {
      this.Notify({
        type: "primary",
        message: this.$t('LogFirst'),
        duration: 1000,
      });
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>