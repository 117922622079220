<template>
  <div class="recommend-container">
    <div class="text">{{$t('RECOMMEND')}}</div>
    <!--begin list -->
    <div class="list">
      <!-- <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in list" :key="item.id">
          <div class="item pointer" @click="toUser(item.id)">
            <div class="image-container">
              <van-image :src="item.avatar" fit="cover" :round="true" />
            </div>
            <div class="name-container van-ellipsis">{{item.name}}</div>
          </div>
        </van-grid-item>
      </van-grid> -->

      <!-- Swiper -->
      <div class="swiper-container" :class="{'padding5':list.length>9}">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(i,index) in listLength9" :key="index">
            <van-grid :column-num="3" :border="false">
              <van-grid-item v-for="item in listSlice(index)" :key="item.id">
                <div class="item pointer" @click="toUser(item.id)">
                  <div class="image-container">
                    <van-image :src="item.avatar" fit="cover" :round="true" />
                  </div>
                  <div class="name-container van-ellipsis">{{item.name}}</div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>

    </div>
    <!-- end list -->

    <div class="ad">
      <a href="https://play16800.com?a=34699">
        <van-image :src="require('@/assets/ad270.gif')" />
      </a>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "Recommend",

  data() {
    return {
      list: [],
    };
  },

  created() {
    this.getList();
  },
  mounted() {},

  methods: {
    getList() {
      this.request("/recommend", {}).then((response) => {
        this.list = response;
        this.$nextTick(() => {
          this.initSwiper();
        });
      });
    },
    toUser(id) {
      this.$router.push("/user/" + id);
    },
    initSwiper() {
      new Swiper(
        ".swiper-container",
        this.list.length > 9
          ? {
              pagination: ".swiper-pagination",
              paginationClickable: true,
            }
          : {}
      );
    },
    listSlice(index) {
      let begin = index * 9;
      let end = (index + 1) * 9;
      return this.list.slice(begin, end);
    },
  },
  computed: {
    listLength9() {
      const count =
        parseInt(this.list.length / 9) + (this.list.length % 9 > 0 ? 1 : 0);
      return count;
    },
  },
};
</script>

<style lang="less" scoped>
@item-width: 74px;
.recommend-container {
  width: 270px;
  margin-left: 15px;

  /deep/ .van-grid-item__content {
    padding: 8px;
  }

  .text {
    font-size: 15px;
    color: #8a96a3;
    font-weight: bold;
    margin: 15px 0px;
  }
  .list {
    padding-bottom: 15px;
    border-bottom: 1px solid @my-border-color;
  }
  .item {
    &:hover {
      .image-container {
        box-shadow: 0 0 1.5px 1.5px @my-blue;
      }
      .name-container {
        color: @my-blue;
        font-weight: bold;
      }
    }
    /deep/ img {
      pointer-events: none;
    }
  }
  .image-container {
    height: @item-width;
    width: @item-width;
    box-sizing: border-box;
    border: 2px solid @my-blue;
    box-shadow: 0 0 0.5px 0.5px @my-blue;
    border-radius: 100%;
    padding: 2px;
    background: white;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  .name-container {
    line-height: 22px;
    color: #242529;
    text-align: center;
    width: @item-width;
  }

  .ad {
    margin-top: 20px;
  }

  .padding5 {
    padding-bottom: 5px;
  }

  .swiper-pagination {
    bottom: 0px;
  }
}
</style>