<template>
  <div>
    <div class="container">
      <van-tabs
        v-model="active"
        line-width="50%"
        swipeable
        animated
        @click="tabClick"
      >
        <van-tab name="tab1">
          <template #title>
            <van-icon
              class="iconfont"
              class-prefix="icon"
              name="jiugongge"
              :class="{ 'icon-active': active === 'tab1' }"
            />
          </template>
          <tab-1 :id="id" />
        </van-tab>

        <van-tab name="tab2">
          <template #title>
            <van-icon
              class="iconfont"
              class-prefix="icon"
              name="biaogebiaodan"
              :class="{ 'icon-active': active === 'tab2' }"
            />
          </template>
          <tab-2 :id="id" v-if="showTab2" />
        </van-tab>
      </van-tabs>
    </div>

    <div class="ad" v-if="!isPc && showAd">
      <!-- <div class="close" @click.stop="showAd=false">
          <van-icon name="cross" size="16" color="#fff" />
        </div> -->
      <a href="https://play16800.com?a=34699">
        <van-image :src="require('@/assets/ad375.gif')" />
      </a>
    </div>
  </div>
</template>

<script>
import Tab1 from "./Tab1.vue";
import Tab2 from "./Tab2.vue";
export default {
  components: { Tab1, Tab2 },
  name: "UserBottom",
  props: ["id"],
  data() {
    return {
      active: "tab1",
      showTab2: false,
      showAd: true,
    };
  },
  methods: {
    tabClick(name) {
      if (name === "tab2" && !this.showTab2) {
        this.showTab2 = true;
      }
    },
  },
  watch: {
    id() {
      this.active = "tab1";
      this.showTab2 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 16vw;
}
.van-icon,
.iconfont {
  color: #323233;
  font-size: 24px;
}
.icon-active {
  color: @my-blue;
}

.ad {
  position: fixed;
  bottom: 0px;
  height: 16vw;
  width: 100%;
  z-index: 1000;
  .close {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1001;
  }
  a {
    width: 100%;
    height: 100%;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
