export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    const result = flag ? true : false;
    console.log("isMobile", result);
    return result;
}

export function videoTime(time) {
    let result = "";
    let temp = time;
    let hour = parseInt(temp / 3600);
    if (hour > 0) {
        result += hour + ":";
    }

    temp = temp % 3600;
    let minute = parseInt(temp / 60);
    if (minute < 10) {
        minute = "0" + minute;
    }
    result += minute + ":";
    temp = temp % 60;
    if (temp < 10) {
        temp = "0" + temp;
    }
    result += temp;
    return result;
}

export function transferBr(str) {
    if (str) {
        return str ? str.replace(/\n/g, "<br>") : "";
    } else {
        return "";
    }
}

export function timeAgo(lang, time) {
    if (!time) {
        return lang === 'th' ? 'ไม่มีบันทึก' : 'no record';
    }

    if (time > 365 * 24 * 60 * 60) {
        const years = parseInt(time / (365 * 24 * 60 * 60));
        if (lang === 'th') {
            return years + " ปีที่แล้ว";
        } else {
            return years === 1 ? years + " year ago" : years + " years ago";
        }
    }
    if (time > 30 * 24 * 60 * 60) {
        const months = parseInt(time / (30 * 24 * 60 * 60));
        if (lang === 'th') {
            return months + " เดือนที่แล้ว";
        } else {
            return months === 1 ? months + " month ago" : months + " months ago";
        }
    }

    if (time > 24 * 60 * 60) {
        const days = parseInt(time / (24 * 60 * 60));
        if (lang === 'th') {
            return days + " วันที่ผ่านมา";
        } else {
            return days === 1 ? days + " day ago" : days + " days ago";
        }
    }

    if (time > 60 * 60) {
        const hours = parseInt(time / (60 * 60));
        if (lang === 'th') {
            return hours + " ชั่วโมงที่แล้ว";
        } else {
            return hours === 1 ? hours + " hour ago" : hours + " hours ago";
        }
    }
    if (time > 60) {
        const minutes = parseInt(time / (60));
        if (lang === 'th') {
            return minutes + " นาทีที่แล้ว";
        } else {
            return minutes === 1 ? minutes + " minute ago" : minutes + " minutes ago";
        }
    }
    return lang === 'th' ? "เพียง" : "just";
}