<template>
  <div class="container">
    <Top :id="countryId" @func="changeCountry" />
    <div class="content" :class="{'pc-content':isPc}">
      <!-- 左侧菜单 -->
      <div class="menu" v-if="isPc">
        <Menu />
      </div>
      <!-- 中间list -->
      <div class="list" :class="{'paddingBottom110':!isPc && showAd,'paddingBottom50':!isPc && !showAd}">
        <van-list v-model="loading" :finished="finished" @load="onLoad" :immediate-check="false" offset="1" :loading-text="$t('Loading')"
          :finished-text="$t('NoMoreData')">
          <div class="item-container">
            <div class="item pointer" v-for="item in hostList" :key="item.id" @click="toUser(item.id)">
              <div class="bg">
                <van-image :src="item.bgImage" fit="cover" radius="6" />
              </div>
              <div class="avatar">
                <div class="avatar-bg">
                  <van-image :src="item.avatar" fit="cover" :round="true" />
                </div>
              </div>
              <div class="bottom" :title="item.name">
                <div class="name">
                  <div class="van-ellipsis">{{item.name}}</div>
                  <div class="icon">
                    <van-image :src="item.icon" fit="cover" radius="1" />
                  </div>
                </div>
                <div class="time">{{timeAgo(item.time)}}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>

      <div class="ad" v-if="!isPc && showAd">
        <!-- <div class="close" @click.stop="showAd=false">
          <van-icon name="cross" size="16" color="#fff" />
        </div> -->
        <a href="https://play16800.com?a=34699">
          <van-image :src="require('@/assets/ad375.gif')" />
        </a>
      </div>
      <!-- 右侧推荐 -->
      <div class="recommend" v-if="isPc">
        <Recommend />
      </div>
    </div>
    <div class="tabbar" v-if="!isPc">
      <Tabbar :tabbarName="tabbarName" />
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Tabbar from "@/components/Tabbar.vue";
import Menu from "@/components/Menu.vue";
import Recommend from "@/components/Recommend.vue";
import { timeAgo } from "@/utils";
export default {
  name: "Index",
  components: { Top, Tabbar, Menu, Recommend },
  created() {
    if (localStorage.getItem("country")) {
      this.countryId = parseInt(localStorage.getItem("country"));
    }
    this.getHostList();
  },

  data() {
    return {
      page: 1,
      pageSize: 20,
      tabbarName: "home",
      countryId: 0,
      loading: false,
      finished: false,
      showAd: true,
      hostList: [],
    };
  },
  methods: {
    getHostList() {
      this.NProgress.start();
      this.loading = true;
      this.request("/host", {
        page: this.page,
        pageSize: this.pageSize,
        countryId: this.countryId,
      }).then((response) => {
        this.hostList =
          this.page === 1 ? response : this.hostList.concat(response);
        this.loading = false;
        this.finished = response.length < this.pageSize ? true : false;
        this.NProgress.done();
      });
    },
    changeCountry(id) {
      if (this.countryId === id) {
        return false;
      }
      this.countryId = id;
      if (id > 0) {
        localStorage.setItem("country", id);
      } else {
        localStorage.removeItem("country");
      }
      this.page = 1;
      this.getHostList();
    },

    timeAgo(time) {
      return timeAgo(this.$i18n.locale, time);
    },

    onLoad() {
      this.page++;
      this.getHostList();
    },
    toUser(id) {
      this.$router.push("/user/" + id);
    },
  },
};
</script>

<style lang="less" scoped>
@item-height: 16.45rem;
@item-base: 12;
.content {
  width: 100%;
  .list {
    width: 100%;
    .item {
      margin: 1rem 1rem 0px 1rem;
      position: relative;
      box-sizing: border-box;
      height: @item-height;
      .bg {
        width: 100%;
        height: 100%;
        position: relative;
        .van-image {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0px;
          right: 0px;
          bottom: 0px;
          &::after {
            left: 0px;
            right: 0px;
            bottom: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.12;
            background: linear-gradient(#000, rgba(138, 150, 163, 0));
            content: "";
            position: absolute;
            border-radius: 6px;
          }
        }
      }
      .avatar {
        position: absolute;
        z-index: 2;
        width: @item-height * 0.781;
        height: @item-height * 0.781;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 1.4rem;
        .avatar-bg {
          box-shadow: 0 0 0 0.25rem #fefefe;
          border: 0.25rem solid white;
          background: #fefefe;
          height: 0px;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border-radius: 100%;
          .van-image {
            width: 100%;
            height: 100%;
          }
        }
      }
      .bottom {
        z-index: 1;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.25);
        font-size: 19px;
        line-height: 24px;
        color: #fefefe;
        font-weight: bold;
        padding-left: 36%;
        padding-top: 3%;
        padding-bottom: 3%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .name {
          display: flex;
          align-items: baseline;
          padding-right: 10px;
          .icon {
            margin-left: 5px;
            height: 24px;
            width: 18px;
          }
        }

        .time {
          font-size: 14px;
          line-height: 18px;
          font-weight: normal;
        }
      }
    }
  }
}

.pc-content {
  width: 1200px;
  margin: 0px auto;
  display: flex;
  .menu {
    width: 299px;
    box-sizing: border-box;
  }
  .recommend {
    width: 299px;
    box-sizing: border-box;
  }

  .list {
    width: 600px;
    border-left: 1px solid @my-border-color;
    border-right: 1px solid @my-border-color;
    box-sizing: content-box;
    .item {
      margin: 10px 10px 0px 10px;
      height: 12.5px * @item-base;
      .avatar {
        width: 12.5px * @item-base * 0.781;
        height: 12.5px * @item-base * 0.781;
        margin-left: 12.5px * 1.4;
        .avatar-bg {
          box-shadow: 0 0 0 10px * 0.25 #fefefe;
          border: 10px * 0.25 solid white;
        }
      }
      .bottom {
        padding-left: 25%;
        padding-top: 2%;
        padding-bottom: 2%;
      }
    }
  }
}

.ad {
  position: fixed;
  bottom: 50px;
  height: 16vw;
  width: 100%;
  z-index: 1000;
  .close {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1001;
  }
  a {
    width: 100%;
    height: 100%;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
}
.paddingBottom50 {
  padding-bottom: 50px;
}
.paddingBottom110 {
  padding-bottom: calc(16vw + 50px);
}
</style>