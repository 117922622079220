<template>
  <div>
    <div class="container">
      <van-nav-bar :title="host.name" left-arrow :fixed="true" z-index="1000" v-show="showTopTitle" @click-left="$router.back()">
        <template #right>
          <van-icon name="search" @click.stop="$router.push('/query')" size="22" />
        </template>
      </van-nav-bar>

      <div class="back-button" @click.stop="$router.back()" v-if="!isPc && !showTopTitle">
        <van-icon name="arrow-left" size="20" color="#ffffff" />
      </div>
      <div class="bg-image" :style="{backgroundImage: 'url(' + host.bgImage + ')'}">
      </div>

      <div class="avatar">
        <van-image :src="host.avatar" fit="cover" round />
      </div>

      <div class="header">
        <div class="header-container">
          <div class="header-left">
            <div class="name">{{host.name}}</div>
            <div class="icon">
              <van-image :src="host.icon" fit="cover" width="18" height="12" />
            </div>
          </div>
          <div class="header-right" border="false" center>
            <van-grid :column-num="2">
              <van-grid-item style="flex-basis:35%;">{{$t('Posts')}}</van-grid-item>
              <van-grid-item style="flex-basis:65%;">{{$t('LastUpdate')}}</van-grid-item>
              <van-grid-item style="flex-basis:35%;">{{host.workCount}}</van-grid-item>
              <van-grid-item style="flex-basis:65%;">{{timeAgo(host.time)}}</van-grid-item>
            </van-grid>
          </div>
        </div>
      </div>

      <div class="desc-container" :class="{'desc-container-hide':showDescHideIcon}" ref="descContainer">
        <div class="desc" v-html="transferBr(host.resume)" ref="desc"></div>
        <div class="desc-arrow" @click="showDescHideIcon=false">
          <van-icon name="arrow-down" size="12" v-show="showDescHideIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/http/request";
import { transferBr, timeAgo } from "@/utils";
export default {
  name: "UserTop",
  props: ["id"],
  data() {
    return {
      host: {},
      showDescHideIcon: true,
      showTopTitle: false,
      scrollTop: 0,
    };
  },
  created() {
    this.getHostInfo();
  },
  activated() {
    this.$nextTick(function () {
      if (!this.isPc) {
        this.handleScroll();
        window.addEventListener("scroll", this.handleScroll);
      }
    });
  },

  deactivated() {
    if (!this.isPc) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    getHostInfo() {
      request("/host/get", { hostId: this.id }).then((response) => {
        this.host = response;
        this.$nextTick(() => {
          if (
            this.$refs.desc.offsetHeight <=
            this.$refs.descContainer.offsetHeight
          ) {
            this.showDescHideIcon = false;
          } else {
            this.showDescHideIcon = true;
          }
        });
      });
    },
    transferBr(str) {
      return transferBr(str);
    },
    timeAgo(time) {
      return timeAgo(this.$i18n.locale, time);
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.body.scrollTop;
      this.showTopTitle = this.scrollTop > 200 ? true : false;
    },
  },
  watch: {
    id() {
      this.host = {};
      this.showDescHideIcon = true;
      this.showTopTitle = false;
      this.scrollTop = 0;
      this.getHostInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
}
.back-button {
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
}

.bg-image {
  height: 160px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // filter: blur(1px);
  z-index: 1;
}

.avatar {
  position: absolute;
  top: 75px;
  left: 20px;
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  background-color: white;
  padding: 2px;
  border-radius: 100%;
  border: 1px solid @my-border-color;
  box-shadow: 0px 0px 8px 3px @my-border-color;
  z-index: 100;
  .van-image {
    width: 100%;
    height: 100%;
  }
}

.header {
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top: 1px solid white;
  background-color: @my-bg-color;
  margin-top: -40px;
  z-index: 2;
  padding-bottom: 20px;
  .header-container {
    margin-top: -45px;
    display: flex;
    padding: 0px 20px;
  }

  .header-left {
    flex: 0 0 50%;
    z-index: 2;
    margin-top: 80px;
    .name {
      line-height: 22px;
      height: 22px;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .header-right {
    flex: 0 0 50%;
    margin-top: 80px;
    .van-grid-item {
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
    }
    /deep/ .van-grid-item__content {
      background-color: transparent;
      padding: 0px;
    }
    /deep/ .van-grid-item__content::after {
      border: 0px;
    }
    /deep/ .van-hairline--top::after {
      border: 0px;
    }
  }
}

.desc-container {
  padding: 0px 20px;
  margin: 20px 0px;
  position: relative;
  .desc {
    font-size: 12px;
    line-height: 20px;
    padding-right: 20px;
  }
  .desc-arrow {
    position: absolute;
    bottom: 0px;
    right: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.desc-container-hide {
  height: 40px;
  overflow: hidden;
}
</style>